import { ComponentProps, useClickOutside } from '@loveholidays/design-system';
import React, { useRef } from 'react';
import { SxStyleProp } from 'theme-ui';

import { getPositionStyles } from './getPositionStyles';
import { AnchorPosition, Size } from './types';

export interface PopoverProps extends ComponentProps {
  trigger?: React.ReactNode;
  anchorPosition: AnchorPosition | [AnchorPosition?, AnchorPosition?, AnchorPosition?];
  isOpen: boolean;
  children: React.ReactNode;
  size: Size | [Size?, Size?, Size?];
  onClickOutside?: () => void;
  contentStyle?: SxStyleProp;
  footerContent?: React.ReactNode;
}

const sizeMap: Record<Size, number | string> = {
  Small: 240,
  Medium: 375,
  Large: 750,
  FullWidth: '100%',
};

export const Popover: React.FC<PopoverProps> = ({
  trigger,
  anchorPosition,
  isOpen,
  size,
  children,
  className,
  contentStyle,
  footerContent,
  onClickOutside,
}) => {
  const clickOutsideRef = useRef(null);

  useClickOutside({
    ref: clickOutsideRef,
    isActive: isOpen,
    onClick: onClickOutside,
  });

  return (
    <div
      ref={clickOutsideRef}
      className={className}
      sx={{
        position: 'relative',
      }}
    >
      {trigger}
      {isOpen && (
        <div
          sx={{
            display: 'inline-block',
            position: 'absolute',
            width: Array.isArray(size) ? size.map((s) => (s ? sizeMap[s] : null)) : sizeMap[size],
            borderRadius: '12',
            overflow: 'hidden',
            backgroundColor: 'backgroundWhite',
            boxShadow: (t) => t.shadows.elevationFixed,
            ...getPositionStyles(anchorPosition),
            zIndex: 'modal',
          }}
        >
          <div
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              sx={{
                padding: 'l',
                ...contentStyle,
              }}
            >
              {children}
            </div>

            {footerContent && (
              <div
                sx={{
                  width: '100%',
                  borderBottomRightRadius: '12',
                  borderBottomLeftRadius: '12',
                  borderTopStyle: 'solid',
                  borderTopColor: 'strokeLightsubtle',
                  borderTopWidth: 1,
                  paddingY: '2xs',
                  paddingX: 'l',
                  backgroundColor: 'backgroundWhite',
                }}
              >
                {footerContent}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
